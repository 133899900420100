import React from 'react';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './BackButton.scss';

const BackButton = ({ location, ...rest }) => {
  // If this is the first page that a User lands on then don't show this button.
  if (location.key === `initial`) {
    return null;
  }

  const backToPreviousPage = () => {
    window.history.back();
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) { // Enter
      backToPreviousPage();
    }
  }

  return (
    <a onClick={backToPreviousPage} {...rest} tabIndex="0" onKeyUp={onKeyUp}>
      <div className="mars-button mars-button--secondary">
        <div className="mars-back-button">
          <MarsIcon className="mars-margin-right--xxs" icon={`left`} />
          <span>Back</span>
        </div>
      </div>
    </a>
  );
};

export default BackButton;
