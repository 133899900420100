import React from 'react';
import getVideoId from 'get-video-id';
import './EmbedVideo.scss';

const EmbedVideo = ({ url, name }) => {
  const video = getVideoId(url || ``);

  if (!video.id || !video.service) {
    return null;
  }

  const getPlayerUrl = (service) => {
    if (service === `youtube`) {
      return `https://youtube.com/embed/${video.id}`;
    }

    if (service === `vimeo`) {
      return `https://player.vimeo.com/video/${video.id}`;
    }

    return null;
  };

  return (
    <div className="mars-embed-video mars-margin-bottom--lg">
      <iframe
        className="mars-embed-video__player"
        src={getPlayerUrl(video.service)}
        title={name || null}
        width="640"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
};

export default EmbedVideo;
