import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ShareContentModal from '@components/molecules/ShareContentModal/ShareContentModal';
import BackButton from '../../molecules/BackButton/BackButton';

import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import Leaf from '@svgs/article-header-leaf.svg';
import Road from '@svgs/article-header-road.svg';

import './ArticleHero.scss';
import { getDrupalURL } from '@helpers';

const ArticleHero = ({
  featured,
  created,
  title,
  image,
  author,
  readTime,
  location
}) => {
  const shareBtnRef = useRef(null);

  const imagePath = getImage(image.path);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => {
    setIsShareModalOpen(false);
    shareBtnRef.current.focus();
  };

  const monthFeatured = created.toLocaleDateString(`en-GB`, {
    month: `long`,
    year: `numeric`
  });

  const dateCreated = created.toLocaleDateString(`en-GB`, {
    day: `numeric`,
    month: `short`,
    year: `numeric`
  });

  return (
    <header className="mars-article-hero mars-padding-y--xl">
      <div className="mars-article-hero__leaf-container">
        <Leaf />
      </div>
      <div className="mars-article-hero__road-container">
        <Road />
      </div>
      <div className=" mars-container">
        <BackButton location={location} className="mars-article-hero__back" />
        <div className="mars-article-hero__inner">
          <div className="mars-article-hero__content mars-margin-y--md">
            <div className="mars-article-hero__preface mars-zeta">
              {`${monthFeatured}${featured ? ` Featured` : ``}`}
            </div>
            <h1 className="mars-article-hero__title mars-beta mars-margin-bottom--md">
              {title}
            </h1>
            <address className="mars-zeta">
              <a rel="author">{author}</a>
            </address>
            <div className="mars-body-large mars-margin-bottom--md">
              <time dateTime={created.toISOString()}>{dateCreated}</time>
              {` `}
              {readTime && (
                <span className="mars-article-hero__readtime">
                  {`${readTime} min read`}
                </span>
              )}
            </div>
            <div>
              <button
                className="mars-button mars-button--icon-button"
                onClick={openShareModal}
                ref={shareBtnRef}>
                <div className="mars-icon-button__icon-wrapper mars-margin-right--xxs">
                  <MarsIcon
                    className="mars-icon-button__icon"
                    icon="share"
                    width="22px"
                    height="22px"
                  />
                </div>
                <span className="mars-icon-button__text">Share</span>
              </button>
            </div>
          </div>
          {image?.path && (
            <div className="mars-article-hero__image">
              <MarsIcon
                icon="dots"
                width="220px"
                height="175px"
                className="mars-article-hero__shape mars-icon--fill mars-icon--brand2"
              />
              {image?.path && (
                // <img src={`${getDrupalURL()}${image.path}`} alt={image.alt} />
                <GatsbyImage image={imagePath} alt={image.alt} />
              )}
            </div>
          )}
        </div>
      </div>
      <ShareContentModal
        shareURL={location.href}
        isOpen={isShareModalOpen}
        onDismiss={closeShareModal}
      />
    </header>
  );
};

export default ArticleHero;

ArticleHero.propTypes = {
  title: PropTypes.string.isRequired,
  featured: PropTypes.bool,
  created: PropTypes.object.isRequired,
  author: PropTypes.string,
  readTime: PropTypes.string,
  image: PropTypes.shape({
    path: PropTypes.string,
    alt: PropTypes.string
  }),
  location: PropTypes.object.isRequired
};
