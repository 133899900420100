import React from 'react';
import { graphql } from 'gatsby';

import munger from './article.munger';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import ArticleTemplate from '@components/templates/ArticleTemplate/ArticleTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const ArticlePage = ({ data, location }) => {
  const articleJson = munger(data);

  return (
    <Layout location={location}>
      <SEO title={articleJson.articleHero.title} />
      <GTMPageLoad
        {...{
          articleTitle: articleJson.articleHero.title,
          page_type: `article`,
          page_taxonomy_field: `Article`,
          page_name: articleJson.articleHero.title
        }}
      />

      <ArticleTemplate location={location} data={articleJson} />
    </Layout>
  );
};

export const query = graphql`
  query ArticlePage($PageId: String!) {
    nodeArticleJson(drupal_id: { eq: $PageId }) {
      drupal_id
      title
      path {
        alias
      }
      created
      field_article_featured
      field_article_read_time
      field_article_authored_by
      relationships {
        field_article_image {
          relationships {
            image {
              uri {
                url {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        field_content_block {
          field_rich_text {
            processed
          }
          relationships {
            field_embed_video {
              name
              field_media_oembed_video
            }
            field_content_block_image {
              image {
                alt
              }
              relationships {
                image {
                  uri {
                    url {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
      field_article_sources {
        title
        url
      }
      field_article_references {
        title
        url
      }
    }
  }
`;

export default ArticlePage;
