import React, { useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ArticleHero from '@components/organisms/ArticleHero/ArticleHero';
import BackButton from '../../molecules/BackButton/BackButton';
import EmbedVideo from '@components/atoms/EmbedVideo/EmbedVideo';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import ShareContentModal from '@components/molecules/ShareContentModal/ShareContentModal';
import { getDrupalURL } from '@helpers';

import './ArticleTemplate.scss';

const ArticleTemplate = ({ location, data }) => {
  const shareBtnRef = useRef(null);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => {
    setIsShareModalOpen(false);
    shareBtnRef.current.focus();
  };

  return (
    <article>
      <ArticleHero id={data.id} location={location} {...data.articleHero} />

      {/* Article Body Block */}
      <div className="mars-container mars-padding-top--xl mars-padding-bottom--sm">
        <div className="mars-grid">
          <div className="mars-body mars-grid-col-3"></div>
          <div className="mars-body mars-grid-col-6">
            {/* Article Body Content */}
            {data.contentBlocks?.map((block, i) => {
              if (block.richText) {
                return (
                  <div
                    key={`${block.id}-${i}`}
                    className="mars-body--large mars-article__richtext">
                    {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
                    <div
                      className="mars-margin-bottom--lg"
                      dangerouslySetInnerHTML={{
                        __html: block.richText
                      }}
                    />
                  </div>
                );
              }

              if (block.video) {
                return (
                  <EmbedVideo
                    url={block.video.url}
                    name={block.video.name || ``}
                  />
                );
              }

              if (block.image) {
                // console.log(block.image);
                // console.log(getImage(block.image.path));
                return (
                  <div
                    key={`${block.id}-${i}`}
                    className="mars-text--center mars-margin-bottom--lg">
                    {/* <img
                      src={`${getDrupalURL()}${block.image.path}`}
                      alt={block.image.alt}
                    /> */}
                    <GatsbyImage
                      image={getImage(block.image.path)}
                      alt={block.image.alt}
                    />
                  </div>
                );
              }
            })}

            {/* Share Button */}
            <button
              className="mars-button mars-button--icon-button mars-margin-bottom--xl"
              onClick={openShareModal}
              ref={shareBtnRef}>
              <div className="mars-icon-button__icon-wrapper mars-margin-right--xxs">
                <MarsIcon
                  className="mars-icon-button__icon"
                  icon="share"
                  width="22px"
                  height="22px"
                />
              </div>
              <span className="mars-icon-button__text">Share</span>
            </button>

            {/* Article References */}
            {data.references && data.references.length > 0 && (
              <div className="mars-epsilon mars-margin-bottom--lg">
                References:
                <ul className="mars-article__related-link">
                  {data.references.map((reference, i) => (
                    <li key={`${reference.title}-${i}`}>
                      <a
                        role="link"
                        className="mars-link--small"
                        href={reference.url}>
                        {reference.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Article Sources */}
            {data.sources && data.sources.length > 0 && (
              <div className="mars-epsilon mars-margin-bottom--lg">
                Sources:
                <ul className="mars-article__related-link">
                  {data.sources.map((source, i) => (
                    <li key={`${source.title}-${i}`}>
                      <a
                        role="link"
                        className="mars-link--small"
                        href={source.url}>
                        {source.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Back Button */}
            <BackButton
              location={location}
              className="mars-inline mars-text--center"
            />
          </div>
        </div>
      </div>

      <ShareContentModal
        shareURL={location.href}
        isOpen={isShareModalOpen}
        onDismiss={closeShareModal}
      />
    </article>
  );
};

export default ArticleTemplate;
