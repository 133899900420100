export default ({ nodeArticleJson }) => {
  const {
    drupal_id,
    title,
    path,
    created,
    field_article_featured,
    field_article_authored_by,
    field_article_read_time,
    field_article_references,
    field_article_sources,
    relationships
  } = nodeArticleJson;

  return {
    id: drupal_id,
    path: path.alias,
    articleHero: {
      created: Object.freeze(new Date(created)),
      featured: field_article_featured,
      title,
      image: {
        path:
          relationships.field_article_image &&
          relationships.field_article_image.relationships.image.uri.url,
        alt: title
      },
      author: field_article_authored_by,
      readTime: field_article_read_time
    },
    references:
      field_article_references &&
      field_article_references.map((reference) => ({
        title: reference.title,
        url: reference.url
      })),
    sources:
      field_article_sources &&
      field_article_sources.map((source) => ({
        title: source.title,
        url: source.url
      })),
    contentBlocks:
      relationships.field_content_block &&
      relationships.field_content_block.reduce((contentBlocks, block) => {
        if (block.field_rich_text) {
          contentBlocks.push({
            richText: block.field_rich_text.processed,
            id: block.id
          });
        }

        if (block.relationships && block.relationships.field_embed_video) {
          contentBlocks.push({
            video: {
              name: block.relationships.field_embed_video.name,
              url: block.relationships.field_embed_video
                .field_media_oembed_video
            }
          });
        }

        if (
          block.relationships &&
          block.relationships.field_content_block_image
        ) {
          contentBlocks.push({
            image: {
              alt: block.relationships.field_content_block_image.image.alt,
              path: block.relationships.field_content_block_image.relationships
                .image.uri.url
            }
          });
        }

        return contentBlocks;
      }, [])
  };
};
